import { ErrorHandler, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable, Observer, of, ReplaySubject, throwError } from 'rxjs';import { catchError, concatMap, filter, first, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IConsumer } from 'src/models/consumer';
import { ICustomerPassWithSiteProducts } from 'src/models/customer-pass';
import { IPromo } from 'src/models/promo';
import { IPassPurchaseDetails } from 'src/models/purchase-details';
import { IPassPaymentAdditionalInfo, WNPaymentResponse } from 'src/models/worldnet-models';
import { ApiService } from 'src/services/api.service';
import { CustomerInformation, ICustomerDashboard } from 'src/models/customer';
import { environment } from 'src/environments/environment';
import { CustomerStateService } from 'src/services/customer-state.service';

@Injectable({
  providedIn: 'root'
})
export class PassPurchaseService {
    public platinum : string = "bace3dcf-4f01-44f7-96dd-cd1e80c2952c";
    public gold: string = "227316fa-af47-4811-8992-949be9c35fe3";
    public silver: string = "170c2796-cb17-4fcb-87e5-d9f778df1cc0";
    public bronze: string = "6db6b50e-f031-4fa8-b706-08df3ad5cd9f";
    public customerId: string = null;
    public customerInformation$ = new Observable<CustomerInformation>(null);
    
    private purchaseDetails = new BehaviorSubject<IPassPurchaseDetails>({
        customerPass: null,
        isGiftPurchase: null,
        phoneNumber: '',
        recipientPhoneNumber: '',
        emailAddress: '',
        activationDate: null,
        monthsDuration: null,
        monthlyPrice: null,
        userConfirmed: false,
        receipt: null,
        toName: null,
        fromName: null,
        promoCode: null,
        customerId: null,
        promoDiscount: null,
        promoName: null,
        tax: null,
        surcharge: null
    })
  private dataLoaded = new BehaviorSubject<boolean>(null);
  private customerPassesWithSiteProducts = new BehaviorSubject<ICustomerPassWithSiteProducts[]>(null);

  private consumer = new BehaviorSubject<IConsumer>(null);
  private themingTier = new BehaviorSubject<'platinum'|'gold'|'silver'|'bronze'|'primary'>('primary');
  private passIndex = new BehaviorSubject<number>(null);
  private promo = new BehaviorSubject<IPromo>(null);
  public price = new BehaviorSubject<number>(null);
  public tax = new ReplaySubject<number>(null);
  public surcharge = new ReplaySubject<number>(null);
  public priceOff = new BehaviorSubject<number>(null);

  public dataLoaded$ = this.dataLoaded.asObservable();
  public purchaseDetails$ = this.purchaseDetails.asObservable();
  public customerPassesWithSiteProducts$ = this.customerPassesWithSiteProducts.asObservable();
  public consumer$ = this.consumer.asObservable();
  public themingTier$ = this.themingTier.asObservable();
  public promo$ = this.promo.asObservable();
  public passIndex$ = this.passIndex.asObservable();

  public promoError: string[];
  public getSiteProductsImages: boolean = undefined;
  public getSiteCustomerPassesKioskVisible: boolean = true;
  public getSiteCustomerPassesMobileVisible: boolean = undefined;

  constructor (
    private apiService: ApiService,
    public customerStateService: CustomerStateService
  ) { }

  public async initData() {
    await this.customerStateService.favoredSiteId$.pipe(
      await switchMap(siteId => {
        if (siteId == null)
                {
                    return of([], []);
                }
                return forkJoin(
                    [
                        this.apiService.getSiteProducts(siteId, false),
                        this.apiService.getSiteCustomerPasses(siteId,true, true)
                    ]
                )
            }),
            catchError(error => of([], []))
        )
        .subscribe(
            ([siteProducts, customerPasses]) => {
               // merge customer passes with site products by matching the customer passes' products' productTemplateIds
               // with the site products' productTemplateIds
               var cpwsp = new Array<ICustomerPassWithSiteProducts>(...customerPasses as any);
               for (let i = cpwsp.length - 1; i >= 0; i--) {
                   const productTemplateIds = cpwsp[i].products.map(p => p.productTemplateId);
                   cpwsp[i].siteProducts = siteProducts.filter(sp => productTemplateIds.includes(sp.productTemplateId));
                }
                this.customerPassesWithSiteProducts.next(cpwsp);
                // get all the unique product description picture keys in order that they appear
                // this will act as the "master list" of product descriptions
                var allPictureKeys = cpwsp.flatMap(t => t.siteProducts).flatMap(t => t.productDescriptions).map(t => t.pictureKey);
                var distinctPictureKeys = new Array<string>(...(new Set(allPictureKeys)));
                
                // Populate the product description array on each site product of each customer pass with dummy descriptions
                // to ensure that each site product contains all of the used picture keys. The dummy descriptions have null
                // `id` fields. This is to ensure that all of the product description images can be displayed by each
                // product, with the unused descriptions shown as disabled or unincluded.
                // This logic assumes that no picture key is used multiple times within a given product, and that picture
                // keys that are used across multiple products appear in the same order.
               for (let i = cpwsp.length - 1; i >= 0; i--) {
                   const customerPass = cpwsp[i];
                   for (let j = customerPass.siteProducts.length - 1; j >= 0; j--) {
                       const siteProduct = customerPass.siteProducts[j];
                       
                       const pictureKeys = siteProduct.productDescriptions.map(t => t.pictureKey);
                       for (let k = 0; k < distinctPictureKeys.length; k++) {
                           const pictureKey = distinctPictureKeys[k];
                           //This is a temporary fix to keep product descriptions from showing on products that arent gold silver or bronze
                           //eventually will need to have a different push
                           if(siteProduct.productTemplateId == this.gold || siteProduct.productTemplateId == this.silver|| siteProduct.productTemplateId == this.bronze){
                               if (!pictureKeys.includes(pictureKey)) {
                                   siteProduct.productDescriptions.push({
                                       id: null,
                                       description: null,
                                       pictureKey: pictureKey,
                                       descriptionImage: null,
                                       descriptionOrder: k,
                                       siteProductId: siteProduct.id
                                    });
                                }
                           }
                       }
                   }
               }
               //console.log(cpwsp);
           },
           error => console.error(error),
           () => {
               this.dataLoaded.next(true);
               this.dataLoaded.complete();
           }
        );
    }

    public safeCreatePartialAccount() {
        const model = this.purchaseDetails.getValue();
        if(model.recipientPhoneNumber === "")
        {
            this.customerStateService.currentCustomerId$.pipe(
                filter(t => t != null),
                switchMap( customerId => {
                  return this.apiService.safeCreatePartialAccount(customerId, model.phoneNumber, model.customerPass.id).pipe(
                    map(res => {
                      return res
                    })
                  )
                })
              
            ).subscribe(
                response => this.consumer.next(response),
                error => console.error(error)
            )
        }
        else
        {
            this.customerStateService.currentCustomerId$.pipe(
                filter(t => t != null),
                switchMap( customerId => {
                    return this.apiService.safeCreatePartialAccount(customerId, model.recipientPhoneNumber, model.customerPass.id).pipe(
                      map(res => {
                        return res
                      })
                    )
                  })
                
                ).subscribe(
                    response => this.consumer.next(response),
                    error => console.error(error)
                )
        }
    }

  getCustomerId(): string{
    let returnId: string
    this.customerStateService.currentCustomerId$.pipe(
      map( customerId=> {
        returnId =  customerId.toString();
      })
    )
    return returnId;
  }

  getSiteId(){
    this.customerStateService.favoredSite$.pipe(
      map( site=> {
        return site.id.toString();
      })
    )
  }

  public getHostedPaymentPage(receiptUrl: string, customerId: string, siteId: string, consumer: IConsumer, price: number, additional: IPassPaymentAdditionalInfo) {
    return this.apiService.getHostedPaymentPage(receiptUrl, customerId, siteId, consumer.id, price, additional);
  }

  promoRedeem(code:number, phoneNumber: string, customerId:string){
    return this.apiService.redeemPromo(code, phoneNumber, customerId).subscribe(redeemed => {
      this.promo.next(redeemed);
    });
  }

  promoValidate(code: number, phoneNumber: string, customerId: string) {
    return this.apiService.validatePromo(code, phoneNumber, customerId);
  }

  getPurchasePrice(){
    var purchaseDetails = this.purchaseDetails.getValue()
    return purchaseDetails.customerPass.passCost
  }
  getPurchaseTax(){
    var purchaseDetails = this.purchaseDetails.getValue()
    return purchaseDetails.tax
  }
  getPurchaseSurcharge(){
    var purchaseDetails = this.purchaseDetails.getValue()
    return purchaseDetails.surcharge
  }

  public resetModel(): void {
    const newValue: IPassPurchaseDetails = {
      customerPass: null,
      isGiftPurchase: null,
      phoneNumber: '',
      recipientPhoneNumber: '',
      emailAddress: '',
      activationDate: null,
      monthsDuration: null,
      monthlyPrice: null,
      userConfirmed: false,
      receipt: null,
      toName: null,
      fromName: null,
      promoCode: null,
      customerId: null,
      promoName: null,
      promoDiscount: null,
      tax: null,
      surcharge: null
    };
    this.purchaseDetails.next(newValue);
    this.consumer.next(null);
    this.promo.next(null);
    this.themingTier.next('primary');
    this.passIndex.next(null);
    this.price.next(null);
    this.tax.next(null);
    this.surcharge.next(null);
  }

  public async setCustomerPass(customerPass: ICustomerPassWithSiteProducts): Promise<void> {
    const model = this.purchaseDetails.getValue();
    const customerPassList = this.customerPassesWithSiteProducts.getValue();
        const index = customerPassList.findIndex(value => value.id === customerPass.id);
    const themingTier: 'platinum'|'gold'|'silver'|'bronze'|'primary' =
      index === 0 ? 'platinum' :
        index === 1 ? 'gold' :
          index === 2 ? 'silver' :
            index === 3 ? 'bronze' :
              'primary';
    model.customerPass = customerPass;

    var siteId = await firstValueFrom(this.customerStateService.favoredSiteId$)
    var customerPassTax = null;

    if (customerPass.tax != null) {
      customerPassTax = customerPass.tax;
    } 

    if (customerPassTax != null) {
      model.tax = customerPassTax;
    } else {
      model.tax = customerPass.sites.find(t => t.id == siteId).tax;
    }

    var customerPassSurcharge = null;

    if (customerPass.surcharge != null) {
      customerPassSurcharge = customerPass.surcharge;
    }

    if (customerPassSurcharge != null) {
      model.surcharge = customerPassSurcharge;
    } else {
      model.surcharge = customerPass.sites.find(t => t.id == siteId).surcharge;
    }
    
    this.purchaseDetails.next(model);
    this.tax.next(model.tax);
    this.surcharge.next(model.surcharge);
    this.themingTier.next(themingTier);
    this.passIndex.next(index);
  }
  public setReceiptCustomerPass(customerPass: ICustomerPassWithSiteProducts): void {
    const model = this.purchaseDetails.getValue();
    model.customerPass = customerPass;
    this.purchaseDetails.next(model);
  }

  public setIsGiftPurchase(value: boolean): void {
    const model = this.purchaseDetails.getValue();
    model.isGiftPurchase = value;
    this.purchaseDetails.next(model);
  }
  public setPhoneNumber(value: string): void {
    const model = this.purchaseDetails.getValue();
    model.phoneNumber = value;
    this.purchaseDetails.next(model);
  }
  public setUserConfirmed(confirmed: boolean, code: number, id: string, price: number, name: string ): void {
    const model = this.purchaseDetails.getValue();
    model.userConfirmed = confirmed;
    model.promoCode = code;
    model.customerId = id;
    model.monthlyPrice = price;
    model.promoName = name;
    this.purchaseDetails.next(model);
  }
  public setGiftPurchaserInfo(phone: string, email: string, months: number, name: string): void {
    const model = this.purchaseDetails.getValue();
    model.phoneNumber = phone;
    model.emailAddress = email;
    model.monthsDuration = months;
    model.fromName = name;
    this.purchaseDetails.next(model);
  }
  public setGiftRecipientInfo(phone: string, name: string): void {
    const model = this.purchaseDetails.getValue();
    model.recipientPhoneNumber = phone;
    model.toName = name;
    this.purchaseDetails.next(model);
  }
  public setActivationDate(date: string | number): void {
    const model = this.purchaseDetails.getValue();
    const activationDate = new Date(date);                          // need to make date is in local time
    activationDate.setUTCHours(0, 0, 0, 0);                         // first zero-out any hour/min/second/millisecond components
    activationDate.setMinutes(activationDate.getTimezoneOffset());  // shift by timezone offset to ensure Date is midnight of selected day in user's timezone
    model.activationDate = activationDate;
    this.purchaseDetails.next(model);
  }
  public setWorldNetReceipt(value: WNPaymentResponse): void {
    const info: IPassPaymentAdditionalInfo =
    {
      consumerId: value.CONSUMERID,
      passId: value.PASSID,
      passCost: value.PASSCOST,
      duration: value.DURATION,
      phone: value.PHONE,
      recipientPhone: value.RECIPIENTPHONE,
      email: value.EMAIL,
      activationDate: value.ACTIVATIONDATE,
      fromName: value.FROMNAME,
      toName: value.TONAME,
      customerId: value.CONSUMERID,
      promoName: value.PROMONAME,
      promoCode: value.PROMOCODE,
    };

    if (info) {

      this.customerPassesWithSiteProducts$.pipe(first(t => t != null)).subscribe(
        customerPasses => {
          const pass = customerPasses.find(t => t.id === info.passId);
          this.setCustomerPass(pass);
        }
      );
      if (this.purchaseDetails.value.customerPass == null) {
        this.apiService.getCustomerPass(info.passId).subscribe(t => {
          this.setReceiptCustomerPass(t);
        })
      }
      if(info.recipientPhone === "")
            {
        this.customerStateService.currentCustomerId$.pipe(
          filter(t => t != null),
          switchMap( customerId =>{
            return this.apiService.safeCreatePartialAccount(customerId, info.phone, info.passId)
          })
        ).subscribe(
          response => this.consumer.next(response),
          error => console.error(error)
        )
      }
      else
            {
        this.customerStateService.currentCustomerId$.pipe(
          filter(t => t != null),
          switchMap( customerId =>{
            return this.apiService.safeCreatePartialAccount(customerId, info.recipientPhone, info.passId)
          })
        ).subscribe(
          response => this.consumer.next(response),
          error => console.error(error)
        )
      }

    }
    const model = this.purchaseDetails.getValue();
    if (info) {
      model.monthlyPrice = Number(info.passCost);
      model.emailAddress = info.email;
      model.phoneNumber = info.phone;
      model.recipientPhoneNumber = info.recipientPhone;
      model.activationDate = info.activationDate ? new Date(info.activationDate) : null;
      model.monthsDuration = Number(info.duration);
      model.toName = info.toName;
      model.fromName = info.fromName;
    }

    model.receipt = value;
    model.receipt.ADDITIONALINFO = JSON.stringify(info);
    this.purchaseDetails.next(model);
  }
  public postPassPurchase(siteId: string, receipt: WNPaymentResponse, consumerId: string, customerPassId:string, textURL: string, thankYouURL: string) {
    const details = this.purchaseDetails.getValue();
    return this.apiService.postPassPurchase(siteId, receipt, consumerId, details,customerPassId,  textURL, thankYouURL);
  }

  public postGiftPassPurchase(siteId:string, receipt: WNPaymentResponse, consumerId: string, customerPassId: string, textURL: string) {
    const details = this.purchaseDetails.getValue();
    return this.apiService.postGiftPassPurchase(siteId, receipt, details, consumerId, customerPassId, textURL);
  }
}
